import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'js/utils/hooks';
import { useLocation } from 'react-router';
import { Navigate, Route, Routes } from 'react-router-dom';

import AppHeader from './components/AppHeader';
import PageHeader from 'js/components/PageHeader';
import NotFoundPage from './components/NotFoundPage';
import Spinner from './components/Spinner';
import { activeOwnerPartyLogoAttributes } from 'js/utils/activeOwnerPartyLogoAttributes';

import { locationChanged } from 'js/store/actions';
import routes, {
  findPageHeaderActions,
  findParentPage,
  findPath,
  hasLocationHeader,
  routeAccess
} from './routes';
import { ToastContainer } from 'react-toastify';
import auth from 'js/utils/auth';
import { setFavicon } from 'js/utils/favicon';
import { set as setLocalStorage } from 'js/utils/storage';
import { setLanguage } from 'js/utils/translate';
import { getUser } from 'js/store/userReducer';
import { getFreightProducts } from 'js/store/freightProductsReducer';
import { getShops } from 'js/scenes/Freight/store/shopsReducer';
import { getServiceSuppliers } from 'js/scenes/Freight/scenes/ServiceSuppliers/store/serviceSuppliersReducer';
import { getConcerns } from 'js/scenes/Freight/scenes/Concerns/store/getConcernsReducer';
import { getCustomerSystems } from 'js/scenes/Freight/scenes/Shops/scenes/Shop/store/customerSystemsReducer';
import { getTransportSolutions } from 'js/store/transportSolutionsReducer';
import { setMediaDevice } from 'js/store/mediaDevice';
import { getProofOfDeliveryOptions } from 'js/store/proofOfDeliveryOptionsReducer';

const AppRouter = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const isAuthenticated = auth.isAuthenticated;
  const activeOwnerParty = useAppSelector((state) => state.user.data.activeOwnerParty);
  const userRoles = useAppSelector((state) => state?.user?.data?.roles);
  const userLoaded = useAppSelector((state) => state?.user?.isLoaded);
  const language = useAppSelector((state) => state.user.data.language);

  const [isDesktop, toggleIsDesktop] = useState(window.innerWidth > 1100);

  const parentPage = findParentPage(location.pathname);
  const locationHeader = hasLocationHeader(location.pathname) || false;
  const pageHeaderActions = findPageHeaderActions(location.pathname, userRoles);

  const userHasRouteAccess = routeAccess(location.pathname, userRoles, activeOwnerParty);

  const route = findPath(location.pathname);

  useEffect(() => {
    dispatch(locationChanged());
  }, [location]);

  useEffect(() => {
    dispatch(setMediaDevice(isDesktop));
  }, [isDesktop]);

  useEffect(() => {
    setFavicon();
    if (isAuthenticated) dispatch(getUser());
    window.addEventListener('resize', () => {
      toggleIsDesktop(window.innerWidth > 1100);
    });
  }, []);

  useEffect(() => {
    if (isAuthenticated && !userLoaded) dispatch(getUser());
  }, [isAuthenticated, userLoaded]);

  useEffect(() => {
    setLanguage(language);
  }, [language]);

  useEffect(() => {
    if (activeOwnerParty) {
      setFavicon(activeOwnerParty);
      setLocalStorage('activeOwnerParty', activeOwnerParty);
      dispatch(getFreightProducts());
      dispatch(getTransportSolutions());
      dispatch(getShops());
      dispatch(getServiceSuppliers());
      dispatch(getConcerns());
      dispatch(getCustomerSystems());
      dispatch(getProofOfDeliveryOptions());
    }
  }, [activeOwnerParty]);

  if (isAuthenticated && !userLoaded) return <Spinner pageSpinner />;
  if (!route) return <NotFoundPage />;

  return (
    <div className={activeOwnerPartyLogoAttributes('name', activeOwnerParty)}>
      <AppHeader isAuthenticated={isAuthenticated} />
      <ToastContainer />
      <div className='page-wrapper'>
        {(!userRoles || !activeOwnerParty) && isAuthenticated && <Spinner pageSpinner />}
        {(parentPage || locationHeader) && isAuthenticated && (
          <PageHeader pageHeaderActions={pageHeaderActions} />
        )}
        {isAuthenticated && userHasRouteAccess && (
          <Routes>
            {routes.map((route) => (
              <Route key={route.name} path={route.path} element={route.component} />
            ))}
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        )}
        {!userHasRouteAccess && <Navigate to='/' replace />}
      </div>
    </div>
  );
};

export default AppRouter;

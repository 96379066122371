import { useEffect, useRef, useState } from 'react';
import auth from 'js/utils/auth';

export default function AuthProvider({ children }) {
  const isMountedRef = useRef(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initAuth = async () => {
      await auth.init();
      setIsInitialized(true);
    };

    if (!isMountedRef.current) {
      void initAuth();
      isMountedRef.current = true;
    }
  }, []);

  useEffect(() => {
    // Redirect to login page
    if (isInitialized && !auth.isAuthenticated) {
      auth.login();
    }
  }, [isInitialized]);

  return isInitialized && auth.isAuthenticated ? children : null;
}

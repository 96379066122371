import economyReducer, { EconomyReducerState } from '../scenes/Economy/index.reducer';
import freightReducer, { FreightReducerState } from '../scenes/Freight/index.reducer';
import ordersReducer, { OrdersReducerState } from '../scenes/Orders/index.reducer';
import homeReducer, { HomeReducerState } from '../scenes/Home/index.reducer';
import shortcutsReducer, { ShortcutsReducerState } from './shortcutsReducer';
import userReducer, { UserReducerState } from './userReducer';
import freightProductsReducer, { FreightProductsReducerState } from './freightProductsReducer';
import ownerPartiesReducer, { OwnerPartiesReducerState } from './ownerPartiesReducer';
import mediaDeviceReducer, { MediaDeviceReducerState } from './mediaDevice';
import transportSolutionsReducer, {
  TransportSolutionsReducerState
} from './transportSolutionsReducer';
import systemInfoReducer from 'js/scenes/Settings/components/SystemMessages/store';
import { SystemInfoState } from 'js/scenes/Settings/components/SystemMessages/interfaces';
import accordionReducer from 'js/components/AccordionV2/store/accordionReducer';
import jobsReducer, { JobsReducerState } from 'js/store/jobsReducer';
import proofOfDeliveryOptionsReducer, {
  ProofOfDeliveryOptionsReducerState
} from 'js/store/proofOfDeliveryOptionsReducer';

const reducers = {
  freightProducts: freightProductsReducer,
  jobs: jobsReducer,
  ownerParties: ownerPartiesReducer,
  mediaDevice: mediaDeviceReducer,
  transportSolutions: transportSolutionsReducer,
  economy: economyReducer,
  freight: freightReducer,
  orders: ordersReducer,
  home: homeReducer,
  systemInfo: systemInfoReducer,
  accordion: accordionReducer,
  shortcuts: shortcutsReducer,
  user: userReducer,
  proofOfDeliveryOptions: proofOfDeliveryOptionsReducer
};

export default reducers;

export interface RootState {
  freightProducts: FreightProductsReducerState;
  jobs: JobsReducerState;
  ownerParties: OwnerPartiesReducerState;
  orders: OrdersReducerState;
  mediaDevice: MediaDeviceReducerState;
  transportSolutions: TransportSolutionsReducerState;
  labels: any;
  reports: any;
  economy: EconomyReducerState;
  freight: FreightReducerState;
  accordion: any;
  systemInfo: SystemInfoState;
  home: HomeReducerState;
  shortcuts: ShortcutsReducerState;
  user: UserReducerState;
  proofOfDeliveryOptions: ProofOfDeliveryOptionsReducerState;
}

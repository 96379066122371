import * as amplitude from '@amplitude/analytics-browser';
import { createSlice } from '@reduxjs/toolkit';
import * as api from '../../utils/rest';
import { locationChanged } from 'js/store/actions';
import auth from 'js/utils/auth';

export interface AuthenticationReducerState {
  isLoading: boolean;
  isAuthenticated: boolean | string;
  error: unknown;
}

const initialState: AuthenticationReducerState = {
  isLoading: false,
  isAuthenticated: auth.isAuthenticated,
  error: null
};

const authenticationSlice = createSlice({
  name: 'Authentication',
  initialState,
  reducers: {
    logoutRequest(state) {
      state.isLoading = true;
    },
    logoutSuccess(state) {
      state.isLoading = false;
      state.isAuthenticated = false;
    },
    logoutFailed(state, action) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(locationChanged, (state) => {
      state.error = null;
    });
  }
});

export const { logoutRequest, logoutSuccess, logoutFailed } = authenticationSlice.actions;

export const logout = () => async (dispatch) => {
  try {
    dispatch(logoutRequest());
    await api.logout();
    auth.logout();
    amplitude.flush();
    dispatch(logoutSuccess());
    window.location.reload();
  } catch (error) {
    dispatch(logoutFailed(error));
  }
};
